.Background {
    height: 100vh;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    .background-container {
        display: inline-block;
        position: relative;
        opacity: 0;
        transform-origin: top left;
        transition: opacity 250ms ease-in;
        &.show {
            opacity: 1;
        }
    }
    .scan-lines {
        animation: scanline-animation 30s infinite;
        background: linear-gradient(to bottom, 
            rgba(0, 0, 0, 0) 50%, 
            rgba(0, 0, 0, 1) 51%
        );
        background-size: 100% 5px;
        bottom: -50px;
        left: -50px;
        opacity: .1;
        position: absolute;
        right: -50px;
        top: -50px;
        z-index: 5;
    }

    .background-image-container {
        position: absolute;
    }
    .glitch {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .glitch.red {
        filter: saturate(10);
    }
    .glitch.blue {
        filter: saturate(20) hue-rotate(200deg);
    }
    

    .background-bales {
    }
    .joey {
        top: 49.1%;
        left: 48.3%;
    }
    .jon {
        top: 41.2%;
        left: 38.4%;
    }
    .foz {
        top: 26%;
        left: 34.9%;
    }
    .bill {
        top: 9%;
        left: 71%;
    }

    /* scan-line animation */
    @keyframes scanline-animation {

        $steps:20;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))}{
                background: linear-gradient(to bottom, 
                    rgba(0, 0, 0, 0) 50%, 
                    rgba(0, 0, 0, (random() + .5)) 51%
                );
                background-size: 100% (random(5) + 2)+px;
                transform: scale((random() / 20) + 1) rotate(random(1)+deg) translate(0, random(2)+px);
            }
        }
    }

    /* glitch animations */
    .gray-animation {
        animation: gray-animation 2s infinite;
    }
    .hide-animation {
        animation: hide-animation 1s infinite;
    }
    .triangle-animation {
        animation: triangle-animation 2s infinite;
    }
    .blur-animation {
        animation: blur-animation 2s infinite;
    }
    .glitch.red {
        opacity: 0;
    }
    .clip-animation .glitch.red {
        opacity: 1;
        animation: red-glitch-animation 2s infinite;
    }
    .glitch.blue {
        opacity: 0;
    }
    .clip-animation .glitch.blue {
        opacity: 1;
        animation: blue-glitch-animation 2s infinite;
    }

    @keyframes gray-animation {
        $steps:20;
        @for $i from 0 through $steps {
            $doAnimation: random();
            #{percentage($i*(1/$steps))}{
                @if ($doAnimation < .5) {
                    filter: saturate(0);
                } @else {
                    filter: saturate(1);
                }
            }
        }
    }

    @keyframes blur-animation {
        $steps:20;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))}{
                filter: blur(random(20)+px);
            }
        }
    }

    @keyframes triangle-animation {
        $steps:20;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))}{
                clip-path: polygon(
                    percentage(random()) percentage(random()), 
                    percentage(random()) percentage(random()), 
                    percentage(random()) percentage(random()), 
                    percentage(random()) percentage(random())
                );
            }
        }
    }

    @keyframes scale-animation {
        $steps:20;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))}{
                transform: scale(random());
                transform-origin: bottom center;
            }
        }
    }

    @keyframes hide-animation {
        $steps:20;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))}{
                opacity: random();
            }
        }
    }
    @keyframes red-glitch-animation {
        $steps:20;
        @for $i from 0 through $steps {
            $top: percentage(random());
            $bottom: percentage(random());
            #{percentage($i*(1/$steps))}{
                clip-path: polygon(
                    0 $top, 
                    100% $top, 
                    100% $bottom, 
                    0 $bottom
                );
            }
        }
    }
    @keyframes blue-glitch-animation {
        $steps:20;
        @for $i from 0 through $steps {
            $top: percentage(random());
            $bottom: percentage(random());
            #{percentage($i*(1/$steps))}{
                clip-path: polygon(
                    0 $top, 
                    100% $top, 
                    100% $bottom, 
                    0 $bottom
                );
            }
        }
    }
}
