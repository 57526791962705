.Header {
    position: relative;

    .title {
        font-size: 36px;
        line-height: 1;
        margin: 0 auto;
        padding-top: 8px;
        z-index: 1;

        @media (min-width: 768px) {
            font-size: 48px;
            padding-top: 16px;
        }
    }
    .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    .title-jojo,
    .title-and,
    .title-the,
    .title-bullets {
        color: #ff63b4;
        text-shadow: 1px 1px 0px rgba(0,0,0,.5);
    }
    .title-jojo,
    .title-and {
        font-family: monospace;
        font-size: 0.8em;
        font-style: italic;
        font-weight: 900;
    }
    .title-the,
    .title-bullets {
        font-family: "Permanent Marker", cursive;
    }

    .title-and {
        position: relative;
        font-size: 0.4em;
    }
    .title-the-bullets {
        text-align: left;
        transform-origin: top left;
        transform: skew(2deg, -10deg);
    }
    .title-the {
        font-size: 0.3em;
        margin-bottom: -8px;
    }
    .title-the-bullets {
        font-size: 1.3em;
    }

    .jojo-glitch-image {
        width: 100%;
    }

    /* glitch animations */
    .title-jojo,
    .title-and,
    .title-the,
    .title-bullets {
        position: relative;
        &:before,
        &:after {
            content: attr(data-text);
            position: absolute;
            overflow: hidden;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
        }

        &.is-animating {
            text-shadow: none;
            &:before,
            &:after {
                opacity: 1;
            }
        }

        &:after {
            left: -3px;
            text-shadow: -2px 0 blue;
            animation-name: glitch-animation-2;
            animation-duration: 1s;
            animation-timing-function: linear;
            animation-delay: 0s;
            animation-iteration-count: infinite;
            animation-direction: alternate-reverse;
        }
        &:before {
            left: 3px;
            text-shadow: -2px 0 red;
            animation-name: glitch-animation-1;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-delay: 0s;
            animation-iteration-count: infinite;
            animation-direction: alternate-reverse;
        }

    }

    @keyframes glitch-animation-1 {
        $steps: 20;
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip-path: inset(percentage(random()) 0 percentage(random()) 0);
          }
        }
    }
    
    @keyframes glitch-animation-2 {
        $steps: 20;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
                clip-path: inset(percentage(random()) 0 percentage(random()) 0);
            }
        }
    }
}
