.SocialMediaList {
    font-size: 18px;
    margin-top: 8px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    li {
        display: inline-block;
    }

    a {
        color: inherit;
        display: inline-block;
        filter: drop-shadow(1px 1px .5px rgba(0,0,0,.5));
        padding: 0 8px;
        &:visited,
        &:focus,
        &:active {
            color: inherit;
        }
    }
}